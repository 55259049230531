import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import "./NavBar.css";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


type Props = {
  address: string,
  setAddress: React.Dispatch<React.SetStateAction<string>>,
}

const NavBar: React.FC<Props> = ({ address, setAddress }) => {
  const [loading, setLoading] = React.useState(false);

  const handleConnect = async () => {
    setLoading(true);
    console.log("InitWeb3");
    const provider = await detectEthereumProvider({ mustBeMetaMask: true });
    if (provider && window.ethereum?.isMetaMask) {
      console.log("Welcome to MetaMask User🎉");

      var web3 = new Web3(Web3.givenProvider);
      web3.eth.defaultChain = "mainnet";

      const accounts = await web3.eth.requestAccounts();
      setAddress(accounts[0])
    } else {
      console.log("Please Install MetaMask🙇‍♂️");
    }
    setLoading(false);
  }

  const sliceAddress = (address: string) => {
    return address.slice(0,8) + "..."
  }

  return (
    <div className="nav-bar" style={{ textAlign: 'right' }}>
      <LightTooltip title="">
        <Button href={"https://opensea.io/collection/pomero-zoological-park"}>View Opensea</Button>
      </LightTooltip>
      <BootstrapTooltip title="">
        <Button href={"https://twitter.com/pomero53"}>Twitter</Button>
      </BootstrapTooltip>

      <BootstrapTooltip title="">
        { address ?
        <LoadingButton
        loadingIndicator="Loading..."
        variant="outlined"
        >
        {sliceAddress(address)}
        </LoadingButton>

        : <LoadingButton
        onClick={handleConnect}
        loading={loading}
        loadingIndicator="Loading..."
        variant="outlined"
        >
        Connect
        </LoadingButton>
        }
      </BootstrapTooltip>
    </div>
  );

}

export default NavBar;


