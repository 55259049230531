import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import NavBar from "./NavBar"
import Header from "./Header";
import Gallery from "./Gallery";

function App() {
  const [address, setAddress] = React.useState("")

  return (
    <div>
      <NavBar address={address} setAddress={setAddress} />
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <Gallery address={address} />
          </>
        } />
      </Routes>
    </div>
  );
}

export default App;
