import React, { useState } from 'react';
import axios from "axios";
import { Grid } from '@mui/material';

import AssetCard from './AssetCard';

axios.defaults.baseURL = 'https://pzp.pokena-nft.com/';

type AssetCards = {
  title: string
  tokenId: string
  image: string
  link: string
  owner: string
  message: string
}

const fetchAssets = async () => {
  const response = await axios.get("/");
  const data = response.data as AssetCards[]

  return data
}

const Loading = () => {
  return <p>Loading...</p>;
}

export type GalleryProps = {
  address: string
}

export const Gallery: React.VFC<GalleryProps> = (props) => {
  const [assets, setAssets] = useState<AssetCards[]>([])

  React.useEffect(() => {
    fetchAssets().then((assets) => {
      setAssets(assets)
    });
  }, []);

  if (props == null) {
    return <Loading />
  }

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      {assets.map((asset, index) => {
        return (
          <Grid item xl={2} lg={3} md={4} sm={6}>
            <AssetCard index={index} address={props.address} title={asset.title} image={asset.image} link={asset.link} owner={asset.owner} message={asset.message} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Gallery;
