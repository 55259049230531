import "bulma/css/bulma.css";

export const Header = () => {
  return (
    <header className="hero is-dark is-bold">
      <img src={"https://pokena-pzp.netlify.app/resources/header.png"} alt="pomero zoological park" />
    </header>
  );
}

export default Header;
