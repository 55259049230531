import React from "react"
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import './Profile.css';

type Props = {
    index: number,
    address: string,
    owner: string,
    prof: string,
}

const EditForm: React.FC<Props> = ({index, address, owner, prof}) => {
    const [editing, setEditing] = React.useState(false)
    const [profile, setProf] = React.useState(prof)


    const handleEditing = () => {
      setEditing(!editing)
    }

    const handleMessage = (event: any) => {
      setProf(event.target.value)
    }

    const handleSubmit = async () => {
      await axios.post("/", {
        index: index,
        message: profile
      });
      setEditing(!editing)
    }

    return (
      <div>
        <form>
          {editing ? (
            <div>
              <TextField type="text" className="editarea"  variant="standard" value={profile} onChange={handleMessage}/>

              <IconButton type="button" onClick={handleSubmit}>
                <CheckIcon />
              </IconButton>
            </div>
          ) : (
            <div>
              <div className="profile" >{profile}</div>
              { address.toUpperCase() === owner.toUpperCase() &&
              <Fab type="button" color="secondary" size="small" onClick={handleEditing}>
                <EditIcon />
              </Fab>
              }
            </div>
          )}
        </form>
      </div>
    )
  }

  export default EditForm;
