import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import './AssetCard.css';
import EditForm from './EditForm';

type Props = {
  index: number,
  address: string,
  title: string,
  image: string,
  link : string,
  owner: string,
  message: string,
}

const omitText = (text: string, index: number) => {
  if (text.length >= index) {
    return text.slice(0, index) + "...";
  }
  return text;
}

const AssetCard: React.FC<Props> = ({index, address, title, image, link, owner, message}) => {
  const omittedTitle = omitText(title, 18);
  const omittedOwnerText = "Owner: " + omitText(owner, 10);
  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="asset link" href={link}>
            <LaunchIcon />
          </IconButton>
        }
        title={omittedTitle}
        subheader={omittedOwnerText}
      />
      <CardMedia
        component="img"
        height="400"
        image={image}
        alt="Assets"
      />
      <CardContent>
        <Typography variant="body1" color="text.secondary">
          Comment
        </Typography>
        <EditForm index={index} address={address} owner={owner} prof={message} />
      </CardContent>
    </Card>
  );
}

export default AssetCard;
